import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ILinkToOriginProps {
  link: string;
  title: string;
}

function LinkToOrigin({ link, title }: ILinkToOriginProps) {
  return (
    <div className="linktoorigin">
      <h3>
        <FontAwesomeIcon icon={"external-link-alt"} />
        Link to the original article:
      </h3>
      <a href={link} target="_blank">
        <h4 className="title">{title}</h4>
      </a>
    </div>
  );
}

export default LinkToOrigin;
