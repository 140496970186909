import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Disclaimer() {
  return (
    <div className="disclaimer">
      <div>
        <FontAwesomeIcon icon={"info-circle"} />
        DISCLAIMER :
      </div>
      <h4>
        The content offered here is from additional work conducted for the
        production of the paper and has not been peer-reviewed. Please reference
        KerNel Biomedical (Rouen, France) for any reuse of all or part of the
        content.
      </h4>
    </div>
  );
}

export default Disclaimer;
