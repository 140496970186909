import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Summary from "../../components/Summary";
import SummarySection from "../../components/SummarySection";
import Disclaimer from "../../components/Disclaimer";
import LinkToOrigin from "../../components/LinkToOrigin";
import ListDrawer from "../../components/ListDrawer";
import Figure from "../../components/Figure";
import Metadata from "../../components/Metadata";

import img from "./image.png";
import fig1 from "./fig 1.jpg";
import fig2 from "./fig 2.png";
import fig3 from "./fig 3.png";
import fig4 from "./fig 4.png";
import table1 from "./table1.png";
import table2 from "./table2.png";

function BenchPhillips() {
  return (
    <>
      <div className="summary-wrapper">
        <Summary>
          <SummarySection title="Context" link="context" />
          <SummarySection title="Study design and methods" link="methods" />
          <SummarySection title="Measurements" link="measurements" />
          <SummarySection title="Results" link="results" />
          <SummarySection title="Conclusion" link="conclusion" />
        </Summary>
      </div>
      <div className="article-wrapper">
        <h1>
          Addition of bacterial filter alters positive airway pressure and
          non-invasive ventilation performances
        </h1>
        <Metadata day={31} month={1} year={2022}>
          Rabec C, Fresnel E, Rétory Y, et al.
        </Metadata>
        <LinkToOrigin
          title="Rabec C, Fresnel E, Rétory Y, et al. Addition of bacterial filter alters positive airway pressure and non-invasive ventilation performances. Eur Respir J 2022; in press"
          link="https://erj.ersjournals.com/content/early/2022/01/20/13993003.02636-2021"
        />
        <article>
          <img src={img} alt="illustration" style={{ width: "80%" }} />
          <section id="context">
            <h4>CONTEXT :</h4>
            <ul>
              <li>
                In 2021, one manufacturer of home ventilators alerted about the
                potential risk of serious injury related to the use of some of
                their positive airway pressure (PAP) and non-invasive ventilator
                (NIV) [
                <a
                  target="_blank"
                  href="https://www.philips.com/c-dam/b2bhc/master/landing-pages/src/update/documents/philips-recall-letter-2021-05-a-2021-06-a.pdf"
                >
                  ref 1
                </a>
                ]
              </li>
              <li>
                The risk is caused by the polyurethane foam used in their
                ventilators. In some cases, the foam broke into the blower and
                could have been inhaled by patients.
              </li>
              <li>
                The manufacturer and some healthcare regulatory agencies
                advocated, as a temporary solution, to modify PAP and NIV
                circuits by adding an inline bacterial filter in order to reduce
                the risk of inhalation [
                <a
                  href="https://aasm.org/clinical-resources/guidance-philips-recall-pap-devices/"
                  target="_blank"
                >
                  ref2
                </a>
                ].
              </li>
              <li>
                The aim of our study was to assess the impact of the adjunction
                of an inline filter in a ventilator circuit used during NIV and
                fixed and auto-titrating PAP.
              </li>
            </ul>
          </section>
          <section id="methods">
            <h4>STUDY DESIGN AND METHODS :</h4>
            <ul>
              <li>
                Experimental setup made of a 3-D printed head mimicking human
                upper airways and trachea connected to an artificial lung (ASL
                5000, IngMar Medical, USA). The artificial lung simulated three
                different lung mechanics reflecting the pulmonary function of
                patients usually established on PAP or NIV.
              </li>
              <li>
                Ventilators were connected to the experimental setup using a
                vented oronasal (AirFit F20, ResMed, USA), a non-vented (Quattro
                FX NV ResMed, USA) with an active expiratory valve and a
                mouthpiece interface (both from Intersurgical, Wokingham, United
                Kingdom).
              </li>
              <li>
                We assessed three mechanical ventilators: DreamStation BiPAP
                AVAPS, BiPAP A40, Trilogy 100 (all Philips Respironics, USA)
                with the following settings:
              </li>
            </ul>
            <table>
              <tr>
                <th>Ventilatory mode</th>
                <th>Settings</th>
              </tr>
              <tr>
                <td>CPAP</td>
                <td>
                  EPAP = 10 cmH<sub>2</sub>O
                </td>
              </tr>
              <tr>
                <td>S mode (NIV)</td>
                <td>
                  IPAP = 15 and 25 cmH<sub>2</sub>O
                  <br /> EPAP = 5 cmH<sub>2</sub>O
                  <br /> Triggering: AutoTrak mode and manual (inspiratory
                  sensitivity: 2 L/min, expiratory sensitivity: 40%)
                  <br /> Rise time = 2
                </td>
              </tr>
              <tr>
                <td>MVP (mouthpiece) </td>
                <td>
                  IPAP = 10 cmH<sub>2</sub>O for pressure mode and VT = 500 ml
                  for volume mode
                  <br /> EPAP = 0 cmH<sub>2</sub>O
                </td>
              </tr>
            </table>
            <ul>
              <li>
                We assessed ventilator performances without any filter and with
                five commercially low-resistance breathing filters:
                Anesth-Guard™ (Teleflex Medical, USA), Clear-Guard 3™
                (Intersurgical, UK), Clear-Guard Midi ™ (Intersurgical, UK), Eco
                SlimLine™ (L3 Medical, France) and Flo-Guard™ (Intersurgical,
                UK).
              </li>
              <li>
                To assess the impact of bacterial filters on the efficacy of
                auto-titrating PAP, we used a DreamStation PAP device set in
                auto-adjusting PAP with a range a pressure from 4 to 20cmH
                <sub>2</sub>O. The ventilator was connected to the artificial
                lung through to a Starling resistance simulating obstructive
                events.
              </li>
            </ul>
            <Figure
              src={fig1}
              type="figure"
              title="Experimental setup used with A: setup used to assess continuous positive airway pressure, non-invasive ventilation and mouth-piece ventilation; B: setup used to asses auto-titrating positive airway pressure (APAP: auto-adjusting positive airway pressure, ASL 5000: artificial lung, CPAP: continuous positive airway pressure, Paw: pressure in simulated airway, Pm: pressure in the simulated mask, Pch: pressure applied to the Starling resistance, Qm: flow in the simulated mask, Qp: flow in the artificial lung)xperimental setup used to assess continuous positive airway pressure, non-invasive ventilation and mouth-piece ventilation."
            />
          </section>
          <section id="measurements">
            <h4>MEASUREMENTS :</h4>
            <ul>
              <li>
                Measurements were performed using the flow and pressure curves
                provided by the artificial lung.
              </li>
              <li>
                We characterized the simulated patient-ventilator asynchrony
                (sPVA) events according to the framework proposed by the
                SomnoNIV group [ref 3].
              </li>
              <li>
                The asynchrony index (AI) was defined as a percentage by
                dividing the number of asynchronous cycles by the total of
                simulated respiratory cycles.
              </li>
              <li>
                For each ventilatory recording, we computed various indicators:
                <ul>
                  <li>
                    <ListDrawer title="For NIV :">
                      <li>
                        Triggering delay (ms), which measured the time lag
                        between the beginning of simulated effort and the onset
                        of pressure support;
                      </li>
                      <li>
                        Inspiratory pressure-time product (PTPt) (cmH
                        <sub>2</sub>
                        O.s), defined as the area under the pressure-time curve
                        between the onset of inspiratory effort and the return
                        to the set positive end-expiratory pressure;
                      </li>
                      <li>
                        Pressure differential (cmH<sub>2</sub>O), defined as the
                        difference between the delivered inspiratory pressure
                        (peak pressure reached during inspiratory
                        pressurization) and the pressure setting;
                      </li>
                      <li>
                        Tidal volume (VT; mL), defined as the difference between
                        the maximal volume delivered within the current cycle to
                        the mechanical lung and the residual volume.
                      </li>
                    </ListDrawer>
                  </li>
                  <li>
                    <ListDrawer title="For CPAP :">
                      <li>
                        Regulation delay (ms), which measures the time lag
                        between the start of simulated effort and the onset of
                        pressure regulation;
                      </li>
                      <li>
                        PTPt (cmH<sub>2</sub>O.s);
                      </li>
                      <li>
                        Maximal delivered pressure (cmH<sub>2</sub>O), defined
                        as the peak pressure reached during the current cycle;
                      </li>
                      <li>Tidal volume (mL).</li>
                    </ListDrawer>
                  </li>
                  <li>
                    <ListDrawer title="For APAP :">
                      <li>
                        Pressure distribution delivered by the ventilator during
                        the simulation;
                      </li>
                      <li>
                        Number and the type of events detected by the ventilator
                        built-in software (Care Orchestrator Essence, Philips
                        Respironics, USA);
                      </li>
                      <li>Number and type of events simulated.</li>
                    </ListDrawer>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <section id="results">
            <h4>RESULTS :</h4>
            <p>
              In total, 6480 cycles were analyzed in NIV, 1620 in CPAP and 1080
              in MPV.
            </p>
            <Figure
              src={table1}
              type="table"
              title="Impact of the addition of an inline bacterial filter on ventilator performances in NIV, MPV and CPAP for each type of filter (* : significantly different from control (No filter), NIV: non-invasive ventilation, MPV: mouthpiece ventilation, CPAP: continuous positive airway pressure)"
            />
            <Figure
              src={table2}
              type="table"
              title="Impact of the addition of an inline bacterial filter on auto-titrating positive airway pressure performances (*: significantly different from control (No filter))"
            />
            <Figure
              src={fig2}
              type="figure"
              title="Impact of the addition of an inline bacterial filter on ventilator during non-invasive ventilation (* : significantly different from control (No filter), F1: Anesth-Guard filter; F2: Clear-Guard 3 filter; F3: Clear-Guard Midi filter; F4: Eco-slim Filter; F5: Flo-Guard filter)"
            />
            <Figure
              src={fig3}
              type="figure"
              title="Impact of the addition of an inline bacterial filter on ventilator during continuous positive airway pressure set at 10cmH<sub>2</sub>O (* : significantly different from control (No filter), F1: Anesth-Guard filter; F2: Clear-Guard 3 filter; F3: Clear-Guard Midi filter; F4: Eco-slim Filter; F5: Flo-Guard filter)"
            />
            <Figure
              src={fig4}
              type="figure"
              title="Impact of the addition of an inline bacterial filter on auto-adjusting positive airway pressure: A) change in the pressure delivered by the auto-adjusting positive airway pressure during the all simulation B) pressure delivered by the auto-adjusting positive airway pressure during the all simulation (AG: Anesth-Guard filter; CG3: Clear-Guard 3 filter; CGM: Clear-Guard Midi filter; ESL: Eco-slim Filter; FG: Flo-Guard filter)"
              size="60%"
            />
          </section>
        </article>
        <section className="conclusion" id="conclusion">
          <div>
            <FontAwesomeIcon icon={"lightbulb"} />
            CONCLUSION :
          </div>
          <h4>
            1. During NIV, adding a bacterial filter significantly increased the
            work of breathing and decreased the delivered volume;
          </h4>
          <h4>
            2. During PAP, adding a bacterial filter increased the work of
            breathing and decreased the delivered pressure;
          </h4>
          <h4>
            3. During autotitrating PAP, the use of bacterial filter resulted in
            lower pressure and inaccurate characterization of respiratory event.
          </h4>
        </section>
        <Disclaimer />
      </div>
      <div className="empty-wrapper"></div>
    </>
  );
}

export default BenchPhillips;
