interface ILimitedStringReturn {
  content: string;
  isLimited: boolean;
}

export function limitedstring(
  str: string,
  maxLength: number
): ILimitedStringReturn {
  const tmp = str.trim();

  if (tmp.length <= maxLength) {
    return {
      content: tmp,
      isLimited: false,
    };
  }
  let limited = str.trim().substring(0, maxLength).trim();
  limited += "…";
  return {
    content: limited,
    isLimited: true,
  };
}
