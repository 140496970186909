import React from "react";

interface IArticleContext {
  fignum: number;
  tabnum: number;
}

export const ArticleContext = React.createContext<IArticleContext>({
  fignum: 0,
  tabnum: 0,
});

export function initContext(): IArticleContext {
  return {
    fignum: 0,
    tabnum: 0,
  };
}

export function useArticleContext() {
  return React.useContext(ArticleContext);
}

export function useNewFigure(): number {
  const ctx = useArticleContext();
  ctx.fignum += 1;
  return ctx.fignum;
}

export function useResetFigure() {
  const ctx = useArticleContext();
  ctx.fignum = 0;
}

export function useNewTable(): number {
  const ctx = useArticleContext();
  ctx.tabnum += 1;
  return ctx.tabnum;
}

export function useResetTable() {
  const ctx = useArticleContext();
  ctx.tabnum = 0;
}
