import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ISummarySectionProps {
  title: string;
  link: string;
}

function SummarySection({ title, link }: ISummarySectionProps) {
  return (
    <a href={`#${link}`}>
      <div className="summarysection">
        <FontAwesomeIcon icon={"caret-right"} />
        {title}
      </div>
    </a>
  );
}

export default SummarySection;
