import React, { useState, useEffect } from "react";
import clx from "clsx";

import { showable } from "../helpers";

interface ISummaryProps {
  children: any;
  showBelow?: number;
}

function Summary({ children, showBelow }: ISummaryProps) {
  const _showBelow = showBelow ?? 250;

  const [show, setShow] = useState(showable(_showBelow));

  const handleScroll = () => {
    setShow(showable(_showBelow));
  };

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return (
    <div
      className={clx("summary", {
        hide: show === false,
      })}
    >
      <h4>Summary</h4>
      <ul>{children}</ul>
    </div>
  );
}

export default Summary;
