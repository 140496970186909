import React from "react";
import Layout from "./Layout";
import Scroll from "./Scroll";

import { ArticleContext, initContext } from "../hooks";

import "../styles/ArticlePage.scss";

interface IArticleRootProps {
  children: any;
}

function ArticleRoot({ children }: IArticleRootProps) {
  return (
    <Layout title="Article" scrollable>
      <Scroll showBelow={250} />
      <div className="article-root">
        <ArticleContext.Provider value={initContext()}>
          {children}
        </ArticleContext.Provider>
      </div>
    </Layout>
  );
}
export default ArticleRoot;
