import React from "react";
import ArticleRoot from "../../components/ArticleRoot";
import BenchPhillipsContent from "../../articles/Bench Phillips/BenchPhillips";

import "../../styles/ArticlePage.scss";

function BenchPhillips() {
  return (
    <ArticleRoot>
      <BenchPhillipsContent />
    </ArticleRoot>
  );
}
export default BenchPhillips;
