import React from "react";

import { useNewFigure, useNewTable } from "../hooks";

interface IFigureProps {
  src: any;
  title: string;
  type: "figure" | "table";
  size?: any;
  label?: string;
  forceBottom?: boolean;
  forceTop?: boolean;
}

function Figure({ src, title, type, size, label }: IFigureProps) {
  let number;

  if (type === "figure") {
    number = useNewFigure();
    label = "Figure";
  } else if (type === "table") {
    number = useNewTable();
    label = "Table";
  }

  return (
    <div className="figure">
      <div style={{ width: size }}>
        <img src={src} alt={title} />
      </div>
      <h4 className="figtitle">
        {`${label} ${number} :`} {title}
      </h4>
    </div>
  );
}

export default Figure;
