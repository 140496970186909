import React from "react";
import { exist, fill0 } from "@ki2/utils";

interface IMetadataProps {
  children: string;
  day?: number;
  month: number;
  year: number;
}

function Metadata({ children, day, month, year }: IMetadataProps) {
  let sdate = `${fill0(month, 2)}/${fill0(year, 4)}`;
  if (exist(day)) {
    sdate = `${fill0(day, 2)}/${sdate}`;
  }

  return (
    <div className="metadata">
      <p>
        Published on {sdate} by {children}
      </p>
    </div>
  );
}

export default Metadata;
