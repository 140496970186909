import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/ListDrawer.scss";

interface IListDrawerProps {
  children: any;
  title: string;
}

function LinkToOrigin({ children, title }: IListDrawerProps) {
  const [open, setOpen] = useState(false);
  let xDrawer;
  let className = "listdrawer";
  let hide = "hide";

  function toggleOpen() {
    if (open === true) {
      setOpen(false);
      xDrawer = null;
    } else {
      setOpen(true);
      xDrawer = { children };
    }
  }

  if (open === true) {
    xDrawer = children;
    className = "draweropen";
    hide = "hide";
  } else {
    xDrawer = <div></div>;
    className = "listdrawer";
    hide = "show";
  }

  return (
    <ul className={className}>
      <span className="clickable" onClick={toggleOpen}>
        <span>{title}</span>
        <FontAwesomeIcon icon={"caret-right"} onClick={toggleOpen} />
        <span className="hide">{hide}</span>
      </span>
      <div>{xDrawer}</div>
    </ul>
  );
}

export default LinkToOrigin;
